/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneVideo as ResolvedStandaloneVideo } from 'base/components/standalone/Video';

export const Video: typeof ResolvedStandaloneVideo = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneVideo {...props} />
        </ErrorBoundary>
    );
});

export type VideoProps = PropsFromComponent<typeof Video>;

/** @deprecated Use slot architecture instead */
export const VideoWith = (extras: DynamicStandaloneExtras): typeof Video => {
    return function Video(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Video');
        return <ResolvedStandaloneVideo {...mergeProps({ options: { theme } }, props)} />;
    }
}