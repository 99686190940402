import { ComponentProps } from '@/types/component';
import { omit } from 'lodash-es';
import { forwardRef } from 'react';

const poster = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NjgiIGhlaWdodD0iMjg5IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNTY4djI4OUgweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjI1OSIgY3k9IjE0NSIgcj0iOSIvPjxjaXJjbGUgY3g9IjI4NC4xOTUiIGN5PSIxNDUiIHI9IjkiLz48Y2lyY2xlIGN4PSIzMDkuNDAyIiBjeT0iMTQ1IiByPSI5Ii8+PC9nPjwvc3ZnPg==`;

interface BaseProps extends ComponentProps<'video'> {
  priority?: boolean; // Consistent with NextImage props
}

// eslint-disable-next-line react/display-name
export const BaseSlot = forwardRef<HTMLVideoElement, BaseProps>(({ children, ...props }, ref) => {
  return (
    <video
      ref={ref}
      muted={true}
      playsInline={true}
      loop={true}
      {...omit(props, 'priority')}
      poster={props.poster || poster}
    >
      {children}
    </video>
  );
});
