import type { LabradorComponent } from '@/types/component';
import type { DirektPostInterface } from 'pages/api/direkt';

import { DirektTeaser } from '@/components/DirektTeaser';
import { Icon } from '@/components/Icon';
import { http } from '@/utils/http';
import { useState } from 'react';
import { useInterval } from 'react-use';

export const LabradorDirektTeaser: LabradorComponent = ({ data, ...props }) => {
  const [posts, setPosts] = useState<DirektPostInterface[]>([]);

  useInterval(() => {
    http.get('/api/direkt').then((response) => {
      setPosts(response?.data || []);
    });
  }, 60 * 1000);

  return (
    <DirektTeaser
      headline={data.headline || 'Direct'}
      posts={posts?.map(({ post, postId, publishedAt }) => ({
        text: post,
        href: `/direkt?ifr-dir-postId=${postId}`,
        time: publishedAt,
      }))}
      button={{
        content: (
          <>
            <span>Visa fler</span>
            <Icon name="teaserArrow" options={{ size: 'extraSmall' }} />
          </>
        ),
        href: '/direkt',
      }}
      {...props}
    />
  );
};
