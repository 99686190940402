import { ImageTheme } from '@/components/Image/theme';
import { TeaserTheme } from '@/components/Teaser/theme';
import { tw } from '@/utils/tw';

export default Object.assign(
  tw.theme({
    extend: TeaserTheme,
    slots: {
      base: [],
      cta: [],
      date: [],
      group: [],
      kicker: [],
      media: [],
      sticker: [],
      tag: [],
      ticker: [],
      title: [],
    },
    variants: {
      orientation: {
        horizontal: {
          kicker: ['md:grid-cols-7:text-body-md'],
          title: ['md:grid-cols-7:text-headline-lg'],
        },
        vertical: {
          title: [
            'grid-cols-7:text-headline-md',

            'md:text-headline-md',
            'md:grid-cols-4:text-headline-lg',
            'md:grid-cols-7:text-headline-xl',
          ],
        },
      },
    },
  }),
  {
    embed: {
      ...TeaserTheme.embed,
      image: ImageTheme,
    },
  },
);
