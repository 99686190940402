import { tw } from '@/utils/tw';

const RadioButtonTheme = tw.theme({
  slots: {
    base: [
      'appearance-none',
      'bg-clip-content',
      'flex',
      'items-center',
      'my-4',
      'rounded-full',

      'focus:shadow-primary',

      'hover:shadow-primary',
    ],
    input: [
      'cursor-pointer',
      'appearance-none',
      'bg-clip-content',
      'border-gray-800',
      'border',
      'h-5',
      'p-1.25',
      'rounded-full',
      'w-5',
      'transition-all',

      'checked:bg-primary-900',
      'checked:focus:bg-black',
      'checked:hover:bg-black',

      'focus:border-2',
      'focus:border-primary-700',
      'focus:p-1',
      'focus:shadow-primary',

      'hover:border-2',
      'hover:border-black',
      'hover:p-1',
      'hover:shadow-primary',
    ],
    label: ['text-headline-xs', 'ml-2.5', 'cursor-pointer'],
  },
});

export default RadioButtonTheme;
