import { ArticleTeaserTheme } from '@/components/ArticleTeaser/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { Teaser as StandaloneTeaser } from '@/components/Teaser';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticleTeaserTheme });

const Base = $({ as: StandaloneTeaser });
const Image = $({ as: StandaloneImage, slot: 'image' });

export const ArticleTeaser = Object.assign(Base, { Image });
