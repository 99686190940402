import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import useLazyCallback from 'lib/hooks/useLazyCallback';
import PlayIcon from 'public/image/video-play-icon.svg';
import { Video } from './Video';

export interface StandaloneVideoProps extends StandaloneComponentProps<typeof Video> {
  icon?: React.ReactNode;
  src?: string;
  link?: Record<'video' | 'icon', OptionalLinkProps>;
  poster?: string;
}

export const StandaloneVideo: StandaloneComponent<StandaloneVideoProps> = ({ src, icon, ...props }) => {
  const { options, poster } = props;
  const { priority, autoPlay } = options || {};

  const ref = useLazyCallback<HTMLVideoElement>(({ current }) => {
    if (current && src) {
      current.src = src;
      current.load();
    }

    if (src && !poster && !autoPlay) {
      current.play().then(() => current.pause());
    }
  });

  const playOnMouseEnter = () => {
    if (!src || autoPlay) return;
    ref.current?.play();
  };

  const pauseOnMouseLeave = () => {
    if (!src || autoPlay) return;
    ref.current?.pause();
  };

  return (
    <Video.Group {...props}>
      <Video
        ref={ref}
        src={priority ? src : undefined}
        onMouseEnter={playOnMouseEnter}
        onMouseLeave={pauseOnMouseLeave}
        {...props}
      />
      <OptionalLink {...mergeProps(props.link?.video, { options: { cover: true } })} />
      <Video.Icon>
        <OptionalLink {...props.link?.icon}>{icon || <PlayIcon />}</OptionalLink>
      </Video.Icon>
    </Video.Group>
  );
};
