import type { TeaserProps } from '@/components/Teaser';
import type { ComponentProps, StandaloneComponent } from '@/types/component';

import { isReactNode } from '@/types/is';
import { ArticleTeaser } from './ArticleTeaser';

export type StandaloneArticleTeaserProps = Omit<TeaserProps, 'media' | 'cta' | 'date'> & {
  image?: ComponentProps<typeof ArticleTeaser.Image> | React.ReactNode;
};

export const StandaloneArticleTeaser: StandaloneComponent<StandaloneArticleTeaserProps> = ({ image, ...props }) => {
  const media = isReactNode(image) ? image : <ArticleTeaser.Image {...image} />;

  return <ArticleTeaser media={media} {...props} cta={undefined} date={undefined} />;
};
