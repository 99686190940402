import { ArticleInTextTeaserTheme } from '@/components/ArticleInTextTeaser/theme';
import { tw } from '@/utils/tw';

export default tw.theme({
  extend: ArticleInTextTeaserTheme,
  variants: {
    orientation: {
      horizontal: {
        base: [],
        cta: [],
        date: [],
        group: [],
        kicker: [],
        media: [],
        sticker: [],
        tag: [],
        ticker: [],
        title: [],
      },
      vertical: {},
    },
  },
  defaultVariants: {
    orientation: 'horizontal',
  },
});
