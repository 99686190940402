import { RichTextBox } from '@/components/RichTextBox';
import { LabradorComponent } from '@/types/component';
import { getLabStyle } from '@/utils/labStyle';
import { mergeProps } from '@/utils/merge';

export const LabradorRichTextBox: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const style = getLabStyle(meta);

  return (
    <RichTextBox
      markup={data.content}
      data-content-index={meta.contentIndex}
      {...mergeProps(
        {
          options: {
            className: 'lab',
            style,
          },
        },
        props,
      )}
    />
  );
};
