/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneArticleInTextTeaser as ResolvedStandaloneArticleInTextTeaser } from 'base/components/standalone/ArticleInTextTeaser';

export const ArticleInTextTeaser: typeof ResolvedStandaloneArticleInTextTeaser = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneArticleInTextTeaser {...props} />
        </ErrorBoundary>
    );
});

export type ArticleInTextTeaserProps = PropsFromComponent<typeof ArticleInTextTeaser>;

/** @deprecated Use slot architecture instead */
export const ArticleInTextTeaserWith = (extras: DynamicStandaloneExtras): typeof ArticleInTextTeaser => {
    return function ArticleInTextTeaser(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ArticleInTextTeaser');
        return <ResolvedStandaloneArticleInTextTeaser {...mergeProps({ options: { theme } }, props)} />;
    }
}