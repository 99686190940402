import { Teaser as StandaloneTeaser } from '@/components/Teaser';
import { Video as StandaloneVideo } from '@/components/Video';
import { VideoTeaserTheme } from '@/components/VideoTeaser/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: VideoTeaserTheme });

const Base = $({ as: StandaloneTeaser });
const Video = $({ as: StandaloneVideo, slot: 'video' });

export const VideoTeaser = Object.assign(Base, { Video });
