import { InputCaptionTheme } from '@/components/InputCaption/theme';
import { tw } from '@/utils/tw';

const Caption = tw.theme({
  extend: InputCaptionTheme,
});

const TextAreaTheme = tw.theme({
  slots: {
    base: ['group', 'flex', 'w-full', 'flex-col', 'items-start', 'gap-y-1'],
    label: [
      'group-data-[status=error]:text-error-600',
      'group-focus-within:text-primary-700',
      'text-body-md',
      'text-gray-700',
    ],
    input: [
      'bg-white',
      'outline-0',
      'outline-offset-1',
      'outline-primary-200',
      'outline',
      'p-2',
      'placeholder:text-gray-700',
      'ring-1',
      'ring-gray-700',
      'ring-inset',
      'rounded',
      'transition-all',
      'w-full',

      'group-data-[status=error]:bg-error-50',
      'group-data-[status=error]:outline-4',
      'group-data-[status=error]:outline-error-200',
      'group-data-[status=error]:outline',
      'group-data-[status=error]:placeholder:text-gray-700',
      'group-data-[status=error]:ring-2',
      'group-data-[status=error]:ring-error-600',
      'group-data-[status=error]:ring-inset',

      'hover:not-focus:bg-white',
      'hover:not-focus:outline-0',
      'hover:not-focus:ring-1',
      'hover:not-focus:ring-black',

      'focus:bg-primary-50',
      'focus:outline-4',
      'focus:ring-2',
      'focus:ring-primary-700',
    ],
    group: ['relative', 'flex', 'w-full', 'flex-col', 'items-start', 'gap-y-1'],
  },
  variants: {
    variant: {
      primary: {
        base: '',
        label: ['transition-color', 'relative', 'text-body-xs'],
        input: 'peer-[.absolute]/icon:pr-7',
      },
      secondary: {
        label: [
          '-translate-y-1/2',
          'absolute',
          'group-data-[filled]:text-body-2xs',
          'group-data-[filled]:top-1',
          'group-data-[filled]:translate-y-1.5',
          'group-focus-within:text-body-2xs',
          'group-focus-within:top-1',
          'group-focus-within:translate-y-1.5',
          'left-2',
          'top-6',
          'transition-all',
          'z-1',
        ],
        input: ['pt-6', 'placeholder:opacity-0'],
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default Object.assign(TextAreaTheme, { Caption });
