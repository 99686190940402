import { ArticleTeaserTheme } from '@/components/ArticleTeaser/theme';
import { VideoTheme } from '@/components/Video/theme';
import { tw } from '@/utils/tw';

export default Object.assign(
  tw.theme({
    extend: ArticleTeaserTheme,
    variants: {
      orientation: {
        horizontal: {},
        vertical: {},
      },
    },
  }),
  {
    embed: {
      ...ArticleTeaserTheme.embed,
      video: tw.theme({
        extend: VideoTheme,
      }),
    },
  },
);
