import { VideoTheme } from '@/components/Video/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import { BaseSlot } from './slots/base';
import { GroupSlot } from './slots/group';

const $ = GenericSlotFactory({ theme: VideoTheme });

const Base = $({ as: BaseSlot });
const Group = $({ as: GroupSlot, slot: 'group' });
const Icon = $({ as: 'span', slot: 'icon' });

export const Video = Object.assign(Base, {
  Group,
  Icon,
});
