import { LoopAround as StandaloneLoopAround } from '@/components/LoopAround';
import { TeaserTheme } from '@/components/Teaser/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TeaserTheme });

const Base = $({ as: 'div' });
const Cta = $({ as: 'span', slot: 'cta' });
const Date = $({ as: 'span', slot: 'date' });
const Group = $({ as: 'hgroup', slot: 'group' });
const Kicker = $({ as: 'p', slot: 'kicker' });
const LoopAround = $({ as: StandaloneLoopAround, slot: 'loop-around' });
const Media = $({ as: 'div', slot: 'media' });
const Sticker = $({ as: 'span', slot: 'sticker' });
const Tag = $({ as: 'span', slot: 'tag' });
const Ticker = $({ as: 'div', slot: 'ticker' });
const Title = $({ as: 'h2', slot: 'title' });

export const Teaser = Object.assign(Base, { Cta, Date, Group, Kicker, LoopAround, Media, Sticker, Tag, Ticker, Title });
