import type { StandaloneComponent } from '@/types/component';
import type { ComponentProps } from 'react';

import { TeaserProps } from '@/components/Teaser';
import { isReactNode } from '@/types/is';
import { VideoTeaser } from './VideoTeaser';

export type StandaloneVideoTeaserProps = Omit<TeaserProps, 'media' | 'cta' | 'date'> & {
  video?: ComponentProps<typeof VideoTeaser.Video> | React.ReactNode;
};

export const StandaloneVideoTeaser: StandaloneComponent<StandaloneVideoTeaserProps> = ({ video, ...props }) => {
  const media = isReactNode(video) ? video : <VideoTeaser.Video {...video} />;

  return <VideoTeaser media={media} {...props} cta={undefined} date={undefined} />;
};
