import { ComponentProps } from '@/types/component';
import { omit } from 'lodash-es';
import { forwardRef } from 'react';

const videoAttributes = [
  'src',
  'autoplay',
  'controls',
  'crossorigin',
  'loop',
  'muted',
  'playsinline',
  'poster',
  'preload',
  'width',
  'height',
  'priority',
] as const;

interface GroupProps extends ComponentProps<'figure'> {}

// eslint-disable-next-line react/display-name
export const GroupSlot = forwardRef<HTMLVideoElement, GroupProps>(({ children, ...props }, ref) => {
  return (
    <figure ref={ref} {...omit(props, ...videoAttributes)}>
      {children}
    </figure>
  );
});
