import { ArticleTeaserTheme } from '@/components/ArticleTeaser/theme';
import { tw } from '@/utils/tw';

export default Object.assign(
  tw.theme({
    extend: ArticleTeaserTheme,
    slots: {
      base: [],
      cta: [],
      date: [],
      group: [],
      kicker: [],
      media: [],
      sticker: [],
      tag: [],
      ticker: [],
      title: [],
    },
    variants: {
      orientation: {
        horizontal: {},
        vertical: {},
      },
    },
    defaultVariants: {
      orientation: 'horizontal',
    },
  }),
  {
    embed: {
      ...ArticleTeaserTheme.embed,
    },
  },
);
